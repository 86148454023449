<script>
import LogoCircle from "../../../../../public/assets/circle-brand.png";
import { SuawMainContent, SuawDashboardSummary, SuawDivider, SuawSection, SuawSidebar, SuawList, SuawLabel, SuawInputGroup } from "@suaw/suaw-component-library";
import MyEventsListPipe from "../components/MyEventsListPipe.vue";
import { UserSeriesAndChapterSubscriptions } from "../../operations.gql";

export default {
  name: "DashboardEvents",
  components: {
    SuawMainContent,
    SuawDashboardSummary,
    SuawDivider,
    SuawSection,
    SuawSidebar,
    SuawList,
    SuawLabel,
    MyEventsListPipe,
    SuawInputGroup
  },
  data() {
    return {
      LogoCircle,
      resultUserSeriesAndChapterSubscriptions: {
        id: "",
        memberChapters: [
          // {
          //   chapter: {
          //     id: "",
          //     photo_url: "",
          //     title: "",
          //     slug: ""
          //   }
          // }
        ],
        memberSeries: [
          // {
          //   id: "",
          //   is_organizer: null,
          //   event_series: {
          //     id: "",
          //     slug: "",
          //     title: "",
          //     photo_url: "",
          //     chapter: {
          //       id: "",
          //       slug: ""
          //     }
          //   }
          // }
        ],
        unpublishedSeries: [
          // {
          //   id: "",
          //   is_organizer: true,
          //   event_series: {
          //     id: "",
          //     slug: "",
          //     title: "",
          //     photo_url: "",
          //     chapter: {
          //       id: "",
          //       slug: ""
          //     }
          //   }
          // }
        ]
      }
    };
  },
  apollo: {
    resultUserSeriesAndChapterSubscriptions: {
      query: UserSeriesAndChapterSubscriptions,
      variables() {
        return {
          user_id: this.$auth.user.id,
          endsAfter: new Date().toISOString()
        };
      },
      skip() {
        return !this.$auth.isAuthenticated;
      },
      fetchPolicy: "no-cache"
    }
  },
  computed: {
    userItem() {
      if (this.$auth.isAuthenticated) {
        const user = this.$auth.user;
        return [
          {
            text: user.display_name,
            avatar: user.avatar_url || user.initials,
            avatarType: user.avatar_url ? "picture" : user.avatar_color,
            linkText: "View Profile",
            linkUrl: {
              name: "UserProfile",
              params: {
                userId: user.id
              }
            }
          }
        ];
      }
      return [];
    },
    chapterListItems() {
      return this.resultUserSeriesAndChapterSubscriptions.memberChapters && this.resultUserSeriesAndChapterSubscriptions.memberChapters.length > 0
        ? this.chapterProps(this.resultUserSeriesAndChapterSubscriptions.memberChapters)
        : [];
    },
    seriesListItems() {
      return this.resultUserSeriesAndChapterSubscriptions.memberSeries && this.resultUserSeriesAndChapterSubscriptions.memberSeries.length > 0
        ? this.seriesProps(this.resultUserSeriesAndChapterSubscriptions.memberSeries)
        : [];
    },
    unpublishedSeriesListItems() {
      return this.resultUserSeriesAndChapterSubscriptions.unpublishedSeries && this.resultUserSeriesAndChapterSubscriptions.unpublishedSeries.length > 0
        ? this.unpublishedSeriesProps(this.resultUserSeriesAndChapterSubscriptions.unpublishedSeries)
        : [];
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.eventsList && this.$refs.eventsList.$apollo) {
        this.$refs.eventsList.$apollo.queries.resultUserEvents.refetch();
      }
    });
  },
  methods: {
    chapterProps(chapterItems) {
      return chapterItems.map(x => ({
        text: x.chapter.title,
        picture: x.chapter.photo_url,
        linkText: "View Chapter",
        linkUrl:
          x.chapter.id && x.chapter.slug
            ? {
                name: "Chapter",
                params: {
                  chapterId: x.chapter.id,
                  chapterSlug: x.chapter.slug
                }
              }
            : null
      }));
    },
    seriesProps(seriesItems) {
      return seriesItems.map(x => ({
        text: x.event_series.title,
        picture: x.event_series.photo_url,
        linkText: "View Series",
        linkUrl:
          x.event_series.chapter.slug && x.event_series.id && x.event_series.slug
            ? x.event_series.upcoming_event[0]?.id
              ? {
                  name: "Series",
                  params: {
                    chapterSlug: x.event_series.chapter.slug,
                    eventId: x.event_series.upcoming_event[0]?.id,
                    seriesSlug: x.event_series.slug
                  }
                }
              : {
                  name: "SeriesOnly",
                  params: {
                    chapterSlug: x.event_series.chapter.slug,
                    seriesId: x.event_series.id
                  }
                }
            : null
      }));
    },
    unpublishedSeriesProps(unpublishedSeriesItems) {
      return unpublishedSeriesItems.map(x => ({
        text: x.event_series.title,
        picture: x.event_series.photo_url || this.LogoCircle,
        linkText: "Continue Editing",
        linkUrl:
          x.event_series.chapter.id && x.event_series.id
            ? {
                name: "SeriesEdit",
                params: {
                  chapterId: x.event_series.chapter.id,
                  seriesId: x.event_series.id
                },
                query: {
                  step: "schedule"
                }
              }
            : null
      }));
    },
    onInPersonClick() {
      this.$router.push({
        name: "ChapterDiscovery"
      });
    },
    onOnlineClick() {
      this.$router.push({
        name: "OnlineEventDiscovery"
      });
    }
  }
};
</script>

<template>
  <SuawMainContent size="large-small-gap">
    <SuawSection>
      <SuawDashboardSummary @in-person-click="onInPersonClick" @online-click="onOnlineClick" />
    </SuawSection>
    <SuawDivider />
    <SuawSection>
      <MyEventsListPipe ref="eventsList" @click="onInPersonClick" @secondary-click="onOnlineClick" />
      <SuawSidebar>
        <SuawInputGroup direction="column">
          <SuawLabel size="medium" weight="bold" label-text="My Profile" />
          <SuawList :items="userItem" />
        </SuawInputGroup>
        <SuawInputGroup v-if="unpublishedSeriesListItems.length > 0" direction="column">
          <SuawLabel size="medium" weight="bold" label-text="My Unpublished Series" />
          <SuawList :items="unpublishedSeriesListItems" />
        </SuawInputGroup>
        <SuawInputGroup v-if="chapterListItems.length > 0" direction="column">
          <SuawLabel size="medium" weight="bold" label-text="My Chapters" />
          <SuawList :items="chapterListItems" />
        </SuawInputGroup>
        <SuawInputGroup v-if="seriesListItems.length > 0" direction="column">
          <SuawLabel size="medium" weight="bold" label-text="My Series" />
          <SuawList :items="seriesListItems" />
        </SuawInputGroup>
      </SuawSidebar>
    </SuawSection>
  </SuawMainContent>
</template>
